<template>
    <div class="container-xl faq-section">
        <BlockTitle :title="$t('titleIndexFaq')" class="text-lg-start"></BlockTitle>

        <AccordionBlock :data="faqSorted" />
        <div class="block-button">
            <ConsultationBlock />
        </div>
    </div>
</template>

<script setup lang="ts">
import BlockTitle from '~/pages/index/components/BlockTitle.vue';
import ConsultationBlock from '~/pages/index/components/ConsultationBlock.vue';
import AccordionBlock from '~/components/Accordion/AccordionBlock.vue';

const props = defineProps<{ data: { answer: string; position: number; question: string }[] }>();

const faq = computed(() => props.data);
const faqSorted = computed(() => faq.value.slice().sort((a, b) => a.position - b.position));
</script>

<style lang="scss" scoped>
.block-button {
    margin-top: 2.5rem;
}
.mt-7 {
    margin-top: 5rem;
}
.faq-section {
    margin-top: 5.94rem;
}

@media (max-width: 780px) {
    .mt-7 {
        margin-top: 3rem;
    }
}
</style>
